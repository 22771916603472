<script setup lang="ts">
const switchLocalePath = useSwitchLocalePath();

defineProps({
  headline: {
    type: String,
    required: true,
  },
});

const id = useId();
</script>

<template>
  <nav :aria-labelledby="id">
    <h2 :id="id" class="sr-only">{{ headline }}</h2>
    <ul class="list-none text-2xl flex gap-4">
      <li class="inline-block">
        <NuxtLink
          :to="switchLocalePath('de')"
          class="flex transform transition-transform hover:scale-110"
          aria-label="Sprache Deutsch wählen"
          ><Icon name="flagpack:de" size="32px"></Icon
        ></NuxtLink>
      </li>
      <li class="inline-block">
        <NuxtLink
          class="flex transform transition-transform hover:scale-110"
          aria-label="Sprache English wählen"
          :to="switchLocalePath('en')"
          ><Icon name="flagpack:gb-ukm" size="32px"></Icon
        ></NuxtLink>
      </li>
    </ul>
  </nav>
</template>
