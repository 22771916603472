export const useFooterStore = defineStore('footer', {
    state: (): StateTree => ({
        footer: null
    }),

    getters: {
        resolvedAddress: (state) => {
            if (state.footer) {
                return renderRichText(state.footer.address?.[0]?.text);
            }
            return null;
        },
    },

    actions: {
        async load() {
            const data = await useAsyncStoryblok(
                'navigation/footer-navigation',
                useStoryblokOptions()
            );
            this.footer = (data?.value?.content as unknown as FooterState);
        },
    },
})