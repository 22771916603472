<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useHeaderStore } from "../store/header";

withDefaults(
  defineProps<{
    isInformational?: boolean;
  }>(),
  {
    isInformational: false,
  }
);

const infoBannerCookie = useCookie('bivignano-info-banner-hide')
const headerStore = useHeaderStore();
const route = useRoute();
const { header } = storeToRefs(headerStore);
const { locale } = useI18n();

onServerPrefetch(async () => {
  await headerStore.load();
});

onMounted(async () => {
  isInitialRender.value = false;

  if (!headerStore.header) {
    await headerStore.load();
  }
});

const isOpen = ref(false);
const isInitialRender = ref(true);

const open = () => (isOpen.value = true);
const close = () => (isOpen.value = false);

watch(
  () => route.path,
  async () => {
    close();
  }
);

watch(
  () => locale.value,
  async () => {
    await headerStore.load();
  }
);

const closeInfoBanner = () => {
  infoBannerCookie.value = String(true);
};

const showInfoBanner = computed(() => {
  const isCookieAvailable = infoBannerCookie.value;
  const isTextAvailable = renderRichText(header.value?.infoBanner).length > 0;
  return !isCookieAvailable && isTextAvailable;
});
</script>

<template>
  <header class="bg-brandDark sticky z-10 top-0 shadow-md">
      <aside
        class="bg-highlight px-4 xs:px-8 xl:px-12 py-4 flex gap-2 justify-between items-start"
        v-if="showInfoBanner"
      >
        <BaseRichtext :content="header.infoBanner"></BaseRichtext>
        <BaseButton
          aria-label="Schließen der Meldung"
          @click="closeInfoBanner"
        >
          <Icon name="mdi:close" size="24px"></Icon>
        </BaseButton>
      </aside>
    <div
      class="max-w-screen-xl my-0 mx-auto px-4 xs:px-8 xl:px-12 flex items-center"
    >
      <div
        class="flex-grow lg:flex-grow-0 flex-shrink-0 text-2xl font-semibold py-4"
      >
        <BaseLink
          v-bind="header?.logoLink"
          :key="locale"
          class="hover:no-underline"
          aria-label="Logo, Link zur Startseite"
          >Casa Bivignano</BaseLink
        >
      </div>
      <template v-if="!isInformational">
        <div class="flex-grow-0 lg:flex-grow flex-shrink-0">
          <div
            class="fixed z-10 top-0 left-0 bg-font lg:hidden"
            :class="{
              'opacity-0': !isOpen,
              'w-full h-full opacity-60': isOpen,
              'transition-opacity': !isInitialRender,
            }"
            @click="close"
          ></div>
          <div
            class="flex flex-col fixed z-10 bg-brandDark top-0 right-0 h-full lg:h-auto w-full transform max-w-screen-xxs lg:max-w-none lg:static lg:transform-none"
            :class="{
              'translate-x-full': !isOpen,
              'translate-x-0': isOpen,
              'transition-transform': !isInitialRender,
            }"
          >
            <div class="flex justify-end lg:hidden pt-4 px-4 lg:p-0">
              <BaseButton
                class="flex"
                aria-label="Schließen der Hauptnavigation"
                @click="close"
              >
                <Icon name="mdi:close" size="32px"></Icon>
              </BaseButton>
            </div>
            <BaseLanguage
              headline="Sprache wählen"
              class="flex lg:hidden px-4 lg:p-0"
            />
            <HeaderNavigation/>
          </div>
          <BaseButton
            class="flex lg:hidden"
            aria-label="Hauptnavigation öffnen"
            :aria-expanded="isOpen"
            aria-haspopup="true"
            @click="open"
          >
            <Icon name="mdi:menu" size="32px"></Icon>
          </BaseButton>
        </div>
        <div class="hidden lg:flex flex-grow-0 flex-shrink-0">
          <BaseButton
            :href="'mailto:' + header?.emailLink"
            class="flex"
            aria-label="Kontakt per Mail aufnehmen"
            @click="useTrackEvent('contactBtnClicked')"
          >
            <Icon name="mdi:email" size="32px"></Icon>
          </BaseButton>
        </div>
      </template>
    </div>
  </header>
</template>
