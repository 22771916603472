import { defineStore, StateTree } from 'pinia';

export const useHeaderStore = defineStore('header', {
    state: (): StateTree => ({
        header: null,
        activeMegaMenuId: undefined
    }),

    actions: {
        async load() {
            const data = await useAsyncStoryblok(
                'navigation/header-navigation',
                useStoryblokOptions()
            );
            this.header = (data?.value?.content as unknown as HeaderState);
        },
    },
})