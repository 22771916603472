<script setup lang="ts">
import { useFooterStore } from "../store/footer";
import BaseSocialMediaLink from "./BaseSocialMediaLink.vue";
import BaseLink from "./BaseLink.vue";
import { storeToRefs } from "pinia";
import { removeWhitespaceFromString } from "@/helpers/text";

withDefaults(
  defineProps<{
    isInformational?: boolean;
  }>(),
  {
    isInformational: false,
  }
);

const footerStore = useFooterStore();
const { footer, resolvedAddress } = storeToRefs(footerStore);
const { locale } = useI18n();

const footerLinkListId = useId();
const partnerLinkListId = useId();
const socialLinkListId = useId();

onServerPrefetch(async () => {
  await footerStore.load();
});

onMounted(async () => {
  if (!footerStore.footer) {
    await footerStore.load();
  }
});

watch(
  () => locale.value,
  async () => {
    await footerStore.load();
  }
);
</script>

<template>
  <footer class="bg-brandDark">
    <div class="px-4 py-8 xs:px-8 xl:p-12 max-w-screen-xl my-0 mx-auto">
      <h2 class="sc-sans-headline text-2xl mb-4 md:mb-10">
        {{ footer?.headline }}
      </h2>
      <div v-if="!isInformational" class="flex flex-wrap -mx-4">
        <div
          class="px-4 pb-8 md:pb-10 flex-shrink-0 w-full md:w-1/2 lg:w-1/3 xl:w-1/4"
        >
          <address class="not-italic">
            <h3 class="sc-sans-headline text-xl mb-4">
              {{ footer?.companyHeadline }}
            </h3>
            <a
              v-if="resolvedAddress"
              :href="footer?.address?.[0]?.link.url || ''"
              class="block"
            >
              <span v-html="resolvedAddress"></span>
            </a>
          </address>
        </div>
        <div
          class="px-4 pb-8 md:pb-10 flex-shrink-0 flex flex-col w-full md:w-1/2 lg:w-1/3 xl:w-1/4"
        >
          <address class="not-italic">
            <h3 class="sc-sans-headline text-xl mb-4">
              {{ footer?.contactHeadline }}
            </h3>
            <a :href="'mailto:' + footer?.email" class="block mb-2" @click="useTrackEvent('contactBtnClicked')">
              <Icon
                name="mdi:email"
                :inline="true"
                class="inline align-middle"
              ></Icon>
              <span class="inline-block ml-1 hover:underline">{{
                footer?.email
              }}</span>
            </a>
            <a
              :href="'tel:' + removeWhitespaceFromString(footer?.telephone)"
              class="block mb-2"
              @click="useTrackEvent('phoneNumberClicked')"
            >
              <Icon
                name="mdi:phone"
                :inline="true"
                class="inline align-middle"
              />
              <span class="inline-block ml-1 hover:underline">{{
                footer?.telephone
              }}</span>
            </a>
            <a
              :href="'tel:' + removeWhitespaceFromString(footer?.mobile)"
              class="block"
              @click="useTrackEvent('phoneNumberClicked')"
            >
              <Icon
                name="mdi:cellphone"
                :inline="true"
                class="inline align-middle"
              />
              <span class="inline-block ml-1 hover:underline">{{
                footer?.mobile
              }}</span>
            </a>
          </address>
        </div>
        <div
          class="px-4 pb-8 md:pb-10 flex-shrink-0 w-full md:w-1\/2 md:w-1/2 lg:w-1/3 xl:w-1/4"
        >
          <nav :aria-labelledby="footerLinkListId">
            <h3 :id="footerLinkListId" class="sc-sans-headline text-xl mb-4">
              {{ footer?.linksHeadline }}
            </h3>
            <ul>
              <li
                v-for="(naviItem, index) in footer?.linkList"
                :key="naviItem.link.cached_url"
                class="not-last:mb-2"
              >
                <BaseLink v-bind="naviItem.link">{{ naviItem.name }}</BaseLink>
              </li>
            </ul>
          </nav>
        </div>
        <div
          class="pb-8 md:pb-10 flex-shrink-0 w-full md:w-1\/2 md:w-1/2 lg:w-2/3 xl:w-1/4 flex flex-wrap gap-y-8"
        >
          <nav
            :aria-labelledby="socialLinkListId"
            class="px-4 flex-shrink-0 w-full lg:w-1/2 xl:w-full"
          >
            <h3 :id="socialLinkListId" class="sc-sans-headline text-xl mb-4">
              {{ footer?.socialHeadline }}
            </h3>
            <BaseSocialMediaLink
              v-for="(link, index) of footer?.socialMediaLinks"
              :key="index"
              :blok="link"
              class="not-last:mr-2"
            />
          </nav>
          <nav
            :aria-labelledby="partnerLinkListId"
            class="px-4 flex-shrink-0 w-full lg:w-1/2 xl:w-full"
          >
            <h3 :id="partnerLinkListId" class="sc-sans-headline text-xl mb-4">
              {{ footer?.partnerHeadline }}
            </h3>
            <ul>
              <li
                v-for="(link, index) in footer?.partnerLinks"
                :key="index"
                class="not-last:mb-2"
              >
                <BaseLink v-bind="link.link" rel="nofollow">{{
                  link.text
                }}</BaseLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="text-sm flex flex-col xs:flex-row xs:items-center">
        <span class="flex-grow w-full xs:w-auto mb-4 xs:mb-0">{{
          footer?.copyright
        }}</span>
        <BaseLanguage
          :headline="footer?.languageHeadline"
          class="flex-grow-0"
        />
      </div>
    </div>
  </footer>
</template>
