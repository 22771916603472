<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useHeaderStore } from "../store/header";
import { onClickOutside } from '@vueuse/core';

const headerStore = useHeaderStore();
const { header } = storeToRefs(headerStore);

onMounted(async () => {
  isInitialRender.value = false;
});

const isInitialRender = ref(true);
const navigationId = useId();
const navRef = ref(null);

onClickOutside(navRef, () => (headerStore.activeMegaMenuId = undefined))
</script>

<template>
    <nav ref="navRef" :aria-labelledby="navigationId" class="overflow-y-auto pt-4 px-4 lg:p-0 lg:items-center">
        <h2 :id="navigationId" class="sr-only">Hauptnavigation</h2>
        <ul
        class="flex flex-col lg:flex-row lg:gap-8 py-4 lg:p-0text-lg lg:text-base lg:justify-center leading:none"
        >
            <li
                v-for="(navItem, index) of header?.navigationLinks"
                :key="navItem.link.cached_url"
                class="mb-4 lg:mb-0 inline-block w-full lg:w-auto"
            >
                <HeaderNavigationItem :blok="navItem"/>
            </li>
        </ul>
    </nav>
</template>