<script setup lang="ts">
import BaseLink from "./BaseLink.vue";

defineProps({
  blok: {
    type: Object,
    required: true,
  },
});

const iconComponents: {
  [key: string]: string;
} = {
  facebook: "mdi:facebook",
  youtube: "mdi:youtube",
  twitter: "mdi:twitter",
  instagram: "mdi:instagram",
};
</script>

<template>
  <BaseLink
    v-bind="blok.link"
    class="text-4xl leading-unset inline-flex hover:underline transform transition-transform hover:scale-110"
    target="_blank"
    :title="blok.type"
  >
    <Icon :name="iconComponents[blok.type]" :aria-label="blok.type"></Icon>
  </BaseLink>
</template>
